import Vue from 'vue'
import Router from 'vue-router'


const mineAddressPage = () => import(/* webpackChunkName: "Login" */ '../pages/h5/mine-address-page')
const shopAddressPage = () => import(/* webpackChunkName: "Login" */ '../pages/h5/shop-address-page')
const orderPage = () => import(/* webpackChunkName: "Login" */ '../pages/h5/order-page')
const shopPage = () => import(/* webpackChunkName: "Login" */ '../pages/h5/shop-info-page')
const loading = () => import(/* webpackChunkName: "MyWallet" */  '../pages/h5/loading')









Vue.use(Router)
let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: '選擇地址',
      meta: {
        title: '選擇地址'
      },
      component: mineAddressPage
    },
    {
      path: '/shop-address-page',
      name: '商家地址',
      meta: {
        title: '商家地址'
      },
      component: shopAddressPage
    },
    {
      path: '/order-page',
      name: '訂單詳情',
      meta: {
        title: '訂單詳情'
      },
      component: orderPage
    },
    {
      path: '/shop-info-page',
      name: '商家詳情',
      meta: {
        title: '商家詳情'
      },
      component: shopPage
    },
    {
      path: '/loading',
      name: '商家詳情',
      meta: {
        title: '商家詳情'
      },
      component: loading
    },
  ]
})

// router.beforeEach((to, from, next) => {
//   let map = document.getElementById('googleMap')
//   if(to.path=='/loading'){
//     next()
//     return
//   }else{
//     if(map){
//       next()
//     }else{
//       let url = to.path+'?'
//       if(JSON.stringify(to.query)!=='{}'){
//         for(let key in to.query){
//           url+=key+'='+to.query[key]+'&'
//         }
//       }
//       window.localStorage.setItem('url',url.substr(0,url.length-1))
//       next({path:'/loading'})
//     }
//   }
// })


export default router