<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {},
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
.gm-style-iw-t {

  button {
    display: none !important;
  }
  &::after {
    height: 0 !important;
  }
}
</style>
