import Vue from 'vue'
// import Vant from 'vant';
import App from './App.vue'
import router from './router/index';
import DateUtil from './utils/date-util'
import less from 'less'
// import 'vant/lib/index.css';
Vue.use(less)
// Vue.use(Vant);

Vue.config.productionTip = false
Vue.prototype.$util={
  ...DateUtil
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
