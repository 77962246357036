/**
 * @desc   格式化時間戳為年月日時分秒
 * @param  {Number,Date} date
 * @param  {String} fmt
 * @return {String}
 */
 function isDate(o) {
  return Object.prototype.toString.call(o) === "[object Date]";
}

/**
 * 時間戳轉：xx天前，xx小時前，xx分鐘前轉換
 * @param {string} time 
 */
const formatPreTime = (time) => {
  time = parseInt(time)
  var dateTime = new Date(time);
  var year = dateTime.getFullYear();
  var month = dateTime.getMonth() + 1;
  var day = dateTime.getDate();
  var hour = dateTime.getHours();
  var minute = dateTime.getMinutes();
  // var second = dateTime.getSeconds();
  var now = new Date().getTime();
  var milliseconds = 0;
  var timeStr;
  milliseconds = now - time;
  if (milliseconds <= 1000 * 60 * 1) {
    timeStr = '剛剛';
  } else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) {
    timeStr = Math.round((milliseconds / (1000 * 60))) + '分鐘前';
  } else if (1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) {
    timeStr = Math.round(milliseconds / (1000 * 60 * 60)) + '小時前';
  } else if (1000 * 60 * 60 * 24 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 3) {
    timeStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + '天前';
  } else if (milliseconds > 1000 * 60 * 60 * 24 * 15) {
    timeStr = year + '-' + month + '-' + day;
  } else {
    timeStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
  }
  return timeStr;
};

/**
 * 時間戳轉：固定日期格式日期
 * @param {string} date 時間
 * @param {string} type 型別
 * @param {string} symbol 拼接符 
 */
const formatDateTime = (preDate, type, symbol) => {
  symbol = symbol ? symbol : '-';
  preDate = parseInt(preDate);
  var date = new Date(preDate);
  var YY = date.getFullYear() + symbol;
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
  var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
  var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
  var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  if (type == 'YY') {
    return YY;
  }
  if (type == 'YYMM') {
    return YY + symbol + MM;
  }
  if (type == 'YYMMDD') {
    return YY + symbol + MM + symbol + DD;
  }
  if (type == 'YYMMDDhhmm') {
    return YY + symbol + MM + symbol + DD + " " + hh + symbol + mm;
  }
  if (type == 'YYMMDDhhmmss') {
    return YY + symbol + MM + symbol + DD + " " + hh + symbol + mm + symbol + ss;
  }
  if (type == 'hhmm') {
    return hh + symbol + mm;
  }
  if (type == 'hhmmss') {
    return hh + symbol + mm + symbol + ss;
  }
}

const timeAgo = (o, format = "yyyy-MM-dd") => {
  let n = new Date().getTime();
  //但在蘋果上是不支援的，必須這樣寫：
  let f = null;
  if (o.indexOf("T") > -1) {
    f = (n - new Date(o).getTime()) / 1000;
  } else {
    f = (n - new Date(o.replace(/-/g, "/")).getTime()) / 1000;
  }
  if (parseInt(f) < 64) {
    return "剛剛";
  } // 小於60秒,剛剛

  let today = formatDate(new Date(), format);
  let day = formatDate(o, format);
  if (today == day) {
    if (o.indexOf("T") > -1) {
      return "今天" + (new Date(o) + "").slice(16, 21);
    } else {
      return "今天" + o.substr(11, 5);
    }
  } else {
    return formatDate(o, format);
  }
};

//2021-07-23T13:39:32.000+0000時間格式轉換
const formatDate = (date, format = "yyyy-MM-dd hh:mm:ss") => {
  if (!date) {
    return "";
  } else {
    let time = new Date(date).getTime();
    return formatTime(time, format);
  }
};

//時間戳轉換
const formatTime = function (date) {
  var fmt =
    arguments.length > 1 && arguments[1] !== undefined
      ? arguments[1]
      : "yyyy-MM-dd hh:mm:ss";
  if (isDate(date) === false) {
    date = new Date(+date);
  }
  var o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds(),
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  }
  return fmt;
};

const getWeekDate = (date) => {
  if (!date) {
    return "";
  } else {
    let now = new Date(date);
    let day = now.getDay();
    let weeks = new Array(
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六"
    );
    // console.log('weeks', day)
    let week = weeks[day];
    return week;
  }
};

//秒數轉分鐘
const _dateMinutes = function (s) {
  let h;
  h = Math.floor(s / 60);
  s = s % 60;
  h += "";
  s += "";
  h = h.length == 1 ? "0" + h : h;
  s = s.length == 1 ? "0" + s : s;
  return h + ":" + s;
};


export default {
   formatPreTime,
   formatDateTime,
   timeAgo,
   formatDate,
   formatTime,
   getWeekDate,
   _dateMinutes,
}